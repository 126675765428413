import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    useReady,
    useContacts,
    useTranslations,
} from '../../hooks';
import {
    Layout,
    InfoBlock,
    BudgetButton,
    TitleAnimation,
    ContentfulImage,
    ScaleAndFadeAnimation,
} from '../../components';

import styles from './Contacts.module.css';

export const Contacts = ({
    data: {
        contentful: {
            contactsPageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const isReady = useReady();
    const contacts = useContacts();
    const translations = useTranslations();

    const { title, image, seo } = page;

    const seoData = {
        title: `${seo.title} - Agostinho & João Pinto, Lda`,
        description: seo.description,
        image: seo.image?.url,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }
            className={ styles.main }>
            <h1 className={ styles.heading }>{ seo.title }</h1>
            <section className={ styles.contactsSection }>
                <div className={ styles.topContainer }>
                    <BudgetButton
                        arrowClassName={ styles.arrow }
                        splittedButtonClassName={ styles.text }
                        className={ classNames(styles.budgetButton, { [styles.visible]: isReady }) } />
                    <div className={ styles.imageContainer }>
                        <div className={ classNames(styles.imageWrapper, { [styles.visible]: isReady }) }>
                            <ContentfulImage
                                src={ image.url }
                                alt={ image.description }
                                className={ styles.image } />
                        </div>
                    </div>
                </div>
                <div className={ styles.bottomContainer }>
                    <div className={ styles.emailContainer }>
                        <TitleAnimation startAnimation={ isReady }>
                            <h2 className={ styles.title }>{ title }</h2>
                        </TitleAnimation>
                        <ScaleAndFadeAnimation startAnimation={ isReady }>
                            <a className={ styles.email } href={ `mailto:${contacts.mainEmail}` }>
                                { contacts.mainEmail }
                            </a>
                        </ScaleAndFadeAnimation>
                    </div>
                    <ul className={ styles.infoContainer }>
                        <ScaleAndFadeAnimation startAnimation={ isReady }>
                            <li style={ { transitionDelay: '1100ms' } } className={ styles.infoItem }>
                                <InfoBlock title={ translations.address } text={ contacts.addressText } />
                            </li>
                        </ScaleAndFadeAnimation>
                        <ScaleAndFadeAnimation startAnimation={ isReady }>
                            <li style={ { transitionDelay: '1300ms' } } className={ styles.infoItem }>
                                <InfoBlock title={ translations.phone } text={ contacts.phone } />
                            </li>
                        </ScaleAndFadeAnimation>
                        <ScaleAndFadeAnimation startAnimation={ isReady }>
                            <li style={ { transitionDelay: '1500ms' } } className={ styles.infoItem }>
                                <InfoBlock title={ translations.fax } text={ contacts.fax } />
                            </li>
                        </ScaleAndFadeAnimation>
                    </ul>
                </div>
            </section>
        </Layout>
    );
};

Contacts.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            contactsPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Contacts;
